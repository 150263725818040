import * as React from "react"
import Dropdowns from "../components/dropdowns"

const Why = ({ title, content, values }) => {
  return (
    <div className="why p40 flex m-wrap">
      <div className="w-50 m-100">
        <p className="m0 h4 mt40 sticky">{title}</p>
      </div>
      <div className="w-50 m-100">
        <div className="content mt40 large max-600">{content}</div>
        <p className="op-50 medium mt40">Our Values</p>
        <div className="values mt40 mb40">
          <Dropdowns dropdowns={values} />
        </div>
      </div>
    </div>
  )
}

export default Why
