import * as React from "react"

const Who = ({ title, content }) => (
  <div className="who-we--are p40">
    <div className="flex mt40 m-wrap">
      <div className="w-50 m-100">
        <p className="h4 m0 m-mb40">{title}</p>
      </div>
      <div className="w-50 m-100">
        <div className="max-600 large m-mb40">{content}</div>
      </div>
    </div>
  </div>
)

export default Who
