import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const CTA = ({title, image, link}) => (
  <div className="cta--banner flex bt1 m-wrap m-reverse">
    <Link to="/contact" className='link w-50 m-100'>
    <div className="flex p40 panel-1 mh-30 link">
      <p className="h4 ma m0 pr20 ">
        <span className="arrow large mr20" />
        <span className="cta--banner-text">Contact Us</span>
      </p>
    </div>
    </Link>
    <Link to={link} className='link w-50 flex m-100'>
      <div className="p40 bg-grey flex w-100 panel-2 mh-30 pos-rel">
        <p to={link} className="h4 ma m0 link pos-rel z-2 light pr20">
          <span className="arrow large white mr20" />
          <span className="cta--banner-text">{title}</span>
        </p>
        <GatsbyImage className="bg-image br-filter" image={image?.gatsbyImageData} alt="CTA Image" />
      </div>
    </Link>
  </div>
)

export default CTA
