import React, { useEffect } from "react"

const Dropdowns = ({ dropdowns, isProfile }) => {
  const onClick = index => {
    var dropdown = document.querySelector(
      '.dropdown[data-index="' + index + '"]'
    )
    if (dropdown.classList.contains("active")) {
      dropdown.classList.remove("active")
      dropdown.querySelector(".content").style.height = "0px"
    } else {
      dropdown.classList.add("active")
      dropdown.querySelector(".content").style.height = dropdown.querySelector(".content .inner").offsetHeight + "px"
    }
  }

  useEffect(() => {
    if (isProfile && window.innerWidth < 768) {
      var dropdowns = document.querySelectorAll(".dropdown")
      dropdowns.forEach(dropdown => {
        dropdown.classList.add("active")
        dropdown.classList.add("mobile")
        dropdown.querySelector(".content").style.height = dropdown.querySelector(".content .inner").offsetHeight + "px"
      })
    }
  }, [isProfile])

  return (
    <div className="dropdowns">
      {dropdowns.map((dropdown, index) => {
        var key = index + "-" + dropdown.title[0] + dropdown.title[1]
        return (
          <div
            className="dropdown"
            role="presentation"
            key={index}
            data-index={key}
            onClick={() => onClick(key)}
          >
            <div className="title bb1 b-grey large pt20 pb20">
              {dropdown.title}
            </div>
            <div className="content medium">
              <div className="inner">
                <div className="pt20 pb20">{dropdown.content}</div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Dropdowns
