import * as React from "react"
import Layout from "../components/layout"
import TitleBanner from "../components/titleBanner"
import FeatureImage from "../components/featureImage"
import CtaText from "../components/ctaText"
import Spacer from "../components/spacer"
import Team from "../components/team"
import Why from "../components/why"
import Who from "../components/who"
import Seo from "../components/seo"
import Cta from "../components/cta"
import { graphql } from "gatsby"

const StudioPage = ({ data }) => {
  var studio = data.studio
  return (
    <Layout>
      <TitleBanner title="Good design for everyone." images={studio.heroImages}/>
      
      <FeatureImage image={studio.teamImage} />
      
      <Who title={studio.whoHeading} content={studio.whoContent} />
      
      <Spacer className='m-hide'/>
      
      <Spacer className='m-hide'/>
      
      <Team team={data.team} />
      
      <Spacer />
      
      <CtaText title={studio.ctaTitle} content={studio.ctaCaption} />
      
      <Why title={studio.whyTitle} content={studio.whyContent} values={studio.value} />
      
      <Cta />
    </Layout>
  )
}

export default StudioPage

export const Head = () => <Seo title="Studio" />

export const query = graphql`
  query StudioQuery {
    studio: datoCmsStudio {
      heroImages {
        gatsbyImageData
      }
      teamImage {
        gatsbyImageData
      }
      whoHeading
      whoContent
      whyTitle
      whyContent
      value {
        content
        title
      }
      ctaTitle
      ctaCaption
    }
    team: allDatoCmsTeam {
      nodes {
        image {
          gatsbyImageData
        }
        jobPosition
        name
        slug
      }
    }
  }
`
