import React, { useEffect, useMemo } from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const TitleBanner = ({ title, images }) => {
  const splitIndex = useMemo(() => title.lastIndexOf(" ", Math.ceil(title.length / 2)), [title])

  useEffect(() => {
    if (window.innerWidth < 768) {
      document.querySelector(".title--main.mobile").classList.add("active")
      document.querySelector(".title--background-image").classList.add("active")

      var countMobile = 1
      var intervalMobile = setInterval(() => {
        if (countMobile < images.length) {
          countMobile++
        } else {
          countMobile = 1
        }
        document.querySelector(".bg-image-mobile.active")?.classList.remove("active")
        document.querySelector(".title--background-image .image:nth-child(" + countMobile + ")")?.classList.add("active")
      }, 750)
    }

    document.querySelector(".title--main").classList.add("active")
    document.querySelector(".title--background").classList.add("active")

    var count = 1
    var interval = setInterval(() => {
      if (count < images.length) {
        count++
      } else {
        count = 1
      }
      document.querySelector(".bg-image.active")?.classList.remove("active")
      document.querySelector(".title--background .image:nth-child(" + count + ")")?.classList.add("active")
    }, 750)
    return () => {
      clearInterval(interval)
      clearInterval(intervalMobile)
    }
  }, [images.length])

  return (
    <>
      <div className='title--main flex h-100 p40 m-flex-column m-hide'>
        <h1 className='title m0 ma text-center pos-rel z-2 '>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </h1>
        <div className='title--background bg-grey pos-rel'>
          {images.map((image, index) => {
            return <GatsbyImage key={index} className={"bg-image image " + (index === 0 ? "active" : "")} image={image?.gatsbyImageData} alt='Studio Image' />
          })}
        </div>
      </div>
      <div className='title--main mobile flex h-100 p40 m-flex-column m-space-between m-show'>
        <h1 className='title--first-half z-2 m-show '>
          <span dangerouslySetInnerHTML={{ __html: title.substring(0, splitIndex) }} />
        </h1>
        <div className='title--background-image mobile bg-grey pos-rel'>
          {images.map((image, index) => {
            return <GatsbyImage key={`${index}a`} className={"bg-image-mobile image mobile  " + (index === 0 ? "active" : "")} image={image?.gatsbyImageData} alt='Studio Image' />
          })}
        </div>
        <h1 className='title--second-half z-2 m-show '>
          <span dangerouslySetInnerHTML={{ __html: title.substring(splitIndex + 1) }} />
        </h1>
      </div>
    </>
  )
}

export default TitleBanner
