import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Team = ({ team }) => {
  return (
    <>
    <div className='bt1a' data-sal/>
    <div className="team p40">
      <div className="title-area mt40 mb40 flex m-mt20">
        <p className="m0 h4">Our Team</p>
        <p className="mla mta mba op-50 m-h4">Total Staff: {team.nodes.length}</p>
      </div>
      <div className="team-members grid m-grid-1">
        {team.nodes.map((member, index) => {
          return (
            <Link className="link m-mb40" key={index} to={"/member/" + member.slug}>
              <div className="team-member">
                <div
                  className="ratio-1-1 bg-grey pos-rel overflow-hidden image-zoom"
                  data-sal
                >
                  <GatsbyImage
                    className="bg-image"
                    image={member.image?.gatsbyImageData}
                    alt={member.name}
                  />
                </div>
                <div className="mt40 m-mt20">
                  <div className='member-info'>
                    <p className="m0 op-50">{member.jobPosition}</p>
                    <p className="h4 m0 mt20 mb40 m-mt0">{member.name}</p>
                   </div>
                  <Link className="button medium mb40 m-m0 m-button-med" to={'/member/'+member.slug}>
                    View Profile <span className="ml20 arrow" />
                  </Link>
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
    </>
  )
}

export default Team
