import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const Image = ({ image, className='' }) => (
  <div className="feature--image">
    <div
      className={"ratio-2-1 bg-grey pos-rel overflow-hidden image-zoom "+className} data-sal data-sal-offset="500" >
      <GatsbyImage className="bg-image" image={image?.gatsbyImageData} alt="Feature Image" />
    </div>
  </div>
)

export default Image
